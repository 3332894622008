import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import SearchTemplate from "../../../../templates/search-results-template-new-homes";
import { navigate } from "@reach/router";
import { navigate as navigateGatsby } from "gatsby";
import { isTablet, isIPad13, isIOS13, isMobile, isMobileOnly, isDesktop, browserName } from 'react-device-detect';
import SeoResult from "../../../../components/seoResult";
import { milesToMeter, isAreaMatch, getLatLan } from "../../../../components/utils/property-search"
import _ from "lodash";
import $ from "jquery"

const initConf = {
    hitsPerPage: 20,
    getRankingInfo: true
}
const index_name = process.env.GATSBY_ALGOLIA_NEW_DEVELOPMENT_INDEX_NAME;

class Search extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            query: "",
            min: "",
            max: "",
            bedrooms: "",
            building: "",
            searchType: "sales",
            department: "residential",
            popular: "",
            page: "",
            mapView: isTablet || isMobile ? false : true,
            radius: 3,
            searchState: {},
            algoliaConfig: initConf,
            areaList: props.data?.allArea?.nodes,
            searchCounty: ""
        }
    }
    changeStateAndUrl = (searchState) => {
        let urls = [];
        var localQuery = localStorage.getItem('searchValue')
        if (localQuery !== null) {
            searchState.query = localQuery;
        }
        if (typeof searchState.query !== "undefined") {
            if (searchState.query !== "") {
                urls.push(`in-${searchState.query}`)
                this.setState({ query: searchState.query })
            }  
        } else {
            if (this.state.query !== "") {
                urls.push(`in-${this.state.query}`)
                this.setState({ query: this.state.query })
            }  
        }

      
        // create building URL
        if (typeof searchState.menu !== "undefined") {
            if (searchState.menu.building) {
                urls.push(`type-${searchState.menu.building}`)
                this.setState({ building: searchState.menu.building })
            } else {
                this.setState({ building: "" })
            }
            if (searchState.menu.accomadation_summary) {
                this.setState({ popular: searchState.menu.accomadation_summary })
            }
        } else if (this.state.building !== "") {
            urls.push(`type-${this.state.building}`)
        }
        // create building URL

        // create bedroom URL
        if (searchState.range && searchState.range.bedrooms) {
            let bedroom = searchState.range.bedrooms.min;
            if (bedroom) {
                urls.push(`${bedroom}-and-more-bedrooms`)
                this.setState({ bedrooms: bedroom })
            } else {
                this.setState({ bedrooms: "" })
            }
        } else if (this.state.bedrooms !== "") {
            urls.push(`${this.state.bedrooms}-and-more-bedrooms`)
        }
        // create bedroom URL

        // create Price min and Max URL
        if (typeof searchState.range !== "undefined") {
            // let price = searchState.range.price;
            let min = searchState.range.min_price?.min;
            let max = searchState.range.min_price?.max;
            if (min && max) {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if (min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else if (this.state.min !== "") {
                    this.setState({ min: "" })
                }
                if (max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else if (this.state.max !== "") {
                    this.setState({ max: "" })
                }
            }
        } else {
            let min = this.state.min;
            let max = this.state.max;
            if (min !== "" && max !== "") {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if (min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else {
                    this.setState({ min: "" })
                }
                if (max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else {
                    this.setState({ max: "" })
                }
            }
        }

        // create radius URL   
        var isMatched = false
        if (!_.isEmpty(searchState.query) && this.state.radius != "") {
            let selectedArea = isAreaMatch(this.state.areaList, searchState.query)
            if (!_.isEmpty(selectedArea) && this.state.radius != "") {
                isMatched = true;
                let algoliaConfig = { ...this.state.algoliaConfig }
                algoliaConfig.aroundLatLng = getLatLan(selectedArea);
                algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
                algoliaConfig.getRankingInfo = true;
                this.setState({ algoliaConfig })
                urls.push(`radius-${this.state.radius}-miles`)
            } else {
                isMatched = false;
                this.setState({ algoliaConfig: initConf })
            }
        } 
        // else if (!_.isEmpty(this.state.query) && this.state.radius != "") {
        //     let selectedArea = isAreaMatch(this.state.areaList, this.state.query)
        //     if (!_.isEmpty(selectedArea) && this.state.radius != "") {
        //         isMatched = true;
        //         let algoliaConfig = { ...this.state.algoliaConfig }
        //         algoliaConfig.aroundLatLng = getLatLan(selectedArea);
        //         algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
        //         this.setState({ algoliaConfig })
        //         urls.push(`radius-${this.state.radius}-miles`)
        //     } else {
        //         isMatched = false;
        //         this.setState({ algoliaConfig: initConf })
        //     }
        // } 
        else {
            isMatched = false;
            this.setState({ algoliaConfig: initConf })
        }
        if (localQuery !== null && isMatched) {
            searchState.query = '';
        } else {
            localStorage.removeItem('searchValue');
            localStorage.removeItem('searchValueFull');
        }


        // if (!_.isEmpty(this.state.radius)) {
        //     urls.push(`radius-${this.state.radius}-miles`)
        // } else {
        //     this.setState({ radius: "" })
        // }


        // Sort path
        // if (typeof searchState.sortBy !== "undefined") {
        //     if (searchState.sortBy) {
        //         if (searchState.sortBy === index_name + "_price_asc") {
        //             urls.push("sortby-price-asc")
        //         }
        //         if (searchState.sortBy === index_name + "_price_desc") {
        //             urls.push("sortby-price-desc")
        //         }
        //         if (searchState.sortBy === index_name + "_most_recent") {
        //             urls.push("sortby-most-recent")
        //         }
        //         if (searchState.sortBy === index_name + "_yield_desc") {
        //             urls.push("higest-yield")
        //         }
        //         if (searchState.sortBy === index_name + "_yield_asc") {
        //             urls.push("lowest-yield")
        //         }
        //     }
        // } else if (this.state.sortBy !== "") {
        //     urls.push(`${this.state.sortBy}`)
        // }
        // Sort path


        if (this.state.page !== "") {
            urls.push(`page-${this.state.page}`)
            searchState.page = this.state.page;
            this.setState({ page: this.state.page })
            setTimeout(() => {
                this.setState({ page: "" })
            }, 1000);

        } else if (this.state.page == "" && searchState.page !== "" && searchState.page > 1) {
            urls.push(`page-${searchState.page}`)
        }

        if (window) {
            this.setState({ searchCounty: window.localStorage.getItem('searchCounty') })
        }

        this.setState({ searchState: searchState })
        let url = urls.join('/');
        let newRoute = '/property/new-homes/for-sale/' + url;
        navigate(newRoute)
    }
    componentDidMount() {
        if (window)
            window.scroll({
                top: -100,
                behavior: 'smooth'
            });

        const { params } = this.props;
        const searchParams = params['*'].split('/');

        
        if (window) {
            this.setState({ searchCounty: window.localStorage.setItem("searchCounty","") })
        }


        var queryParam = "";
        searchParams.forEach(param => {
            if (param.includes("in-")) {
                // if(param.includes("in-")) {
                queryParam = param.substring(3);
                this.setState({ query: param.substring(3) })
            }
            if (param.includes("under-")) {
                this.setState({ max: param.substring(6) })
            }
            if (param.includes("over-")) {
                this.setState({ min: param.substring(5) })
            }
            if (param.includes("-and-more-bedrooms")) {
                this.setState({ bedrooms: param.split("-")[0] })
            }
            if (param.includes("type-")) {
                this.setState({ building: param.split("-")[1] })
            }
            if (param.indexOf("page-") >= 0) {
                this.setState({ page: param.replace("page-", "") })
            }
            if (param.indexOf("sortby-") >= 0) {
                this.setState({ sortBy: param })
            }
            if (param.includes("radius-")) {
                let radius = param.split("-")[1];
                if (!_.isEmpty(queryParam) && radius != "") {
                    let selectedArea = isAreaMatch(this.state.areaList, queryParam)
                    if (!_.isEmpty(selectedArea) && radius != "") {
                        let algoliaConfig = { ...this.state.algoliaConfig }
                        algoliaConfig.aroundLatLng = getLatLan(selectedArea);
                        algoliaConfig.aroundRadius = milesToMeter(this.state.radius);
                        algoliaConfig.getRankingInfo = true;
                        this.setState({ radius, algoliaConfig }, () => {
                            this.changeStateAndUrl({ query: queryParam })
                        })
                    } else {
                        this.setState({ algoliaConfig: initConf })
                    }
                } else {
                    this.setState({ algoliaConfig: initConf })
                }
            }
            if (param.includes("between-")) {
                this.setState({
                    min: param.split("-")[1],
                    max: param.split("-")[3]
                })
            }
        });

        if (sessionStorage.getItem('MainView') == 'Grid') {
            this.setState({ mapView: true })
        }


        $(document).mouseup(function (e) {
            var container = $(".dropdown-exp");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".dropdown-exp").removeClass("active");
            }
        });

    }
    // componentDidUpdate(prevProps) {
    //     // console.log("ccc1", prevProps, this.props.location)
    //     if (prevProps.location.pathname !== this.props.location.pathname) {

    //         $(window).on('popstate', function (event) {
    //             localStorage.removeItem('searchValue');
    //             window.location.href = prevProps.location.pathname;
    //         });
    //         // this.setState({ backenable: true }, () => {
    //         //     this.componentDidMount()
    //         // })
    //     }
    // }
    changeToMapUrl = () => {
        this.setState({ mapView: true })
        localStorage.setItem("map-hits", JSON.stringify([]));
        localStorage.setItem("map-bounds", '');
    }
    changeToListUrl = () => {
        this.setState({ mapView: false })
    }

    changeRadius = (radius) => { 
        if (radius != "") {
            var radius_query = this.state.searchState.query
            if (typeof radius_query == "undefined") {
                radius_query = this.state.query
            }
            this.setState({ radius, query: radius_query }, () => {
                this.changeStateAndUrl(this.state.searchState)
            })
        } else {
            this.setState({ radius: 3 }, () => { this.changeStateAndUrl(this.state.searchState) });
        }
    }



    render() {
        var search_area = "";
        var price_range = "", property_type = "", bedrooms = "";
        var fullarea = typeof window !== 'undefined' && localStorage ? localStorage.getItem('searchValueFull') : ''
        if (this.props.params["*"]) {
            search_area = fullarea ? fullarea : this.props.params["*"].split("/").filter(c => c.includes("in")).length > 0 ? "in " + this.props.params["*"].split("/").filter(c => c.includes("in"))[0].split("in-")[1].split("-").map(c => _.upperFirst(c)).join(" ") : "";
            
            price_range = this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("below-") || c.includes("between-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("below-") || c.includes("between-"))[0].split("-").map(c => isNaN(c) ? c : `£${parseInt(c).toLocaleString()}`).join(" ") : "";

            // price_range = this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("below-") || c.includes("between-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("below-") || c.includes("between-"))[0].split("-").join(" ") : "";
            property_type = this.props.params["*"].split("/").filter(c => c.includes("type-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("type-"))[0].split("type-").map(c => _.upperFirst(c))[1] : "";
            bedrooms = this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms"))[0].split("-and-more-bedrooms")[0] : "";
        }

        var h1 = (price_range !== "" && property_type !== "" && bedrooms !== "") ?
            `${bedrooms} Bedroom ${property_type} for Sale ${search_area}` :
            (property_type !== "" && bedrooms !== "") ?
                `${bedrooms} Bedroom New Homes ${property_type} for Sale in ${search_area}` :
                (price_range !== "" && bedrooms !== "") ?
                    `${bedrooms} Bedroom for Sale in ${search_area}` :
                    (price_range !== "" && property_type !== "") ?
                        `New Homes ${property_type} for Sale in ${search_area}` :
                        (bedrooms !== "") ?
                            `${bedrooms} Bedroom for Sale in ${search_area}` :
                            (property_type !== "") ?
                                `New Homes ${property_type} for Sale ${search_area ? `in `+search_area : ''}` :
                                (price_range !== "") ?
                                    `New Homes for sale ${search_area}` :
                                    `New Homes for sale ${search_area}`

                                    var desc =
                                    (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                                        `Explore ${bedrooms} bedroom ${property_type} for sale ${search_area} ${price_range} available with Wards. Contact one of our estate agents for assistance in finding your property ${search_area}.` :
                                        (property_type !== "" && bedrooms !== "") ?
                                            `Find the latest ${property_type} for sale ${search_area} with more than ${bedrooms} bedrooms. Contact Wards estate agents and letting agents ${search_area}, to arrange a viewing.` :
                                            (price_range !== "" && bedrooms !== "") ?
                                                `Explore the list of property for sale ${search_area} with more than ${bedrooms} bedrooms ${price_range} with Wards and request a viewing for the property that interests you.` :
                                                (price_range !== "" && property_type !== "") ?
                                                    `Check out the latest collection of ${property_type} for sale ${price_range} ${search_area ? search_area : 'Kent'} ${price_range} with Wards estate agents ${search_area ? search_area : 'Kent'}.` :
                                                    (bedrooms !== "") ?
                                                        `Explore property for sale ${search_area ? search_area : 'Kent'} with more than ${bedrooms} bedrooms here. Contact our expert estate agents today to get assistance in finding the right property ${search_area ? search_area : 'Kent'}.` :
                                                        (property_type !== "") ?
                                                            `Looking for a ${property_type} for sale? Wards estate agents are here to help.` :
                                                            (price_range !== "") ?
                                                                `Locate the right property for sale ${search_area} ${price_range} with Wards estate agents. Book an appointment with one of our property experts ${search_area ? search_area : 'Kent'}.` :
                                                                `Explore our latest collection of property for sale ${search_area}. Contact Wards estate agents ${search_area ? search_area : 'Kent'} to find the right property for you.`
                        
        return (
            <>
                <SeoResult
                    title={h1}
                    description={
                        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                            `Check out ${bedrooms} bedroom ${property_type} for sale in Kent ${price_range} with Wards, our experts help you find the perfect ${property_type} in Kent.` :
                            (property_type !== "" && bedrooms !== "") ?
                                `Discover the best ${bedrooms} bedroom ${property_type} for sale in Kent, Contact Wards leading estate and letting agents in Kent.` :
                                (price_range !== "" && bedrooms !== "") ?
                                    `Find ${bedrooms} bedroom property for sale in Kent with Wards, You can book a free property valuation with us for accurate property valuations.` :
                                    (price_range !== "" && property_type !== "") ?
                                        `Here is the latest ${property_type} for sale in Kent for you. Contact Wards, the leading estate and letting agents in the Kent today.` :
                                        (bedrooms !== "") ?
                                            `Browse ${bedrooms} bedroom property for sale in Kent. Wards offers a wide range of Property for sale in Kent.` :
                                            (property_type !== "") ?
                                                `Looking for ${property_type} for sale in Kent? check here for the finest collection of Buckinghamshire property. property experts. Book an appointment with one of our specialists today.` :
                                                (price_range !== "") ?
                                                    `Discover the wide range of Property for Sale ${search_area} ${price_range} with Wards. Book an appointment with one of our specialists today to find the perfect property for sale anywhere in Kent.` :
                                                    `Find the best property for Sale ${search_area}. Contact our property experts and get help to find Property for Sale in Kent`
                    }
                    county={this.state.searchCounty}

                />
                <SearchTemplate
                    {...this.state}
                    changeStateAndUrl={this.changeStateAndUrl}
                    changeToMapUrl={this.changeToMapUrl}
                    changeToListUrl={this.changeToListUrl}
                    changeRadius={this.changeRadius}
                    location={this.props.location}
                    search_area={search_area}
                    bedrooms={bedrooms}
                    property_type={property_type}
                    h1={h1}
                    desc={`${desc}`}
                // mapView={sessionStorage.getItem('ResultsView') == 'gridview'}
                />
            </>
        )
    }
}

export default Search;


export const pageQuery = graphql`
query {
    allArea {
      nodes {
        id
        name
        latitude
        longitude
        districtCountyTown
      }
    }
  }
`