import React, { useState, useEffect } from "react";
import algoliasearch from 'algoliasearch/lite';
import { connectStats, RefinementList, InstantSearch, Configure, connectHits, connectInfiniteHits, connectStateResults, connectGeoSearch } from 'react-instantsearch-dom';
import { isTablet, isIPad13, isIOS13, isMobile, isMobileOnly, isDesktop, browserName } from 'react-device-detect';
// import Loader from "../components/loader";
import { Col, Container, Row, Form } from 'react-bootstrap';
import $ from "jquery"
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import markerImageSrc from "../images/map-marker.svg";
import NoImage from "../images/no-image.jpg"
import FilterBlock from "../components/SearchResult/FilterBlock/FilterBlockNewHomes";
import SearchList from "../components/SearchResult/SearchList/SearchListNewHomes";
import "../components/SearchResult/SearchResult.scss"
import { NEW_HOMES_SEARCH_PAGE_URL } from "../components/common/site/constants"
import LoadExternalScript from "../components/utils/load-external-script";
import classNames from 'classnames';


import Loadable from "@loadable/component"
// const MapResultsDesktop = Loadable(() => import("../components/map/search-results-new-homes-locrating-map"));
import MapResultsDesktop from "../components/map/search-results-os-map"
import "../components/map/osmap.scss"
import * as L from 'leaflet';
//import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import "leaflet.markercluster";

const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_API_KEY);
const index_name = process.env.GATSBY_ALGOLIA_NEW_DEVELOPMENT_INDEX_NAME;

const CustomInfiniteHits = connectHits(SearchList);

// No Results
const NoStats = ({ processingTimeMS, nbHits }) => {
    return (
        <>
            {nbHits === 0 &&
                <Container>
                    <Row>
                        <Col lg={12} className="text-center no-properties-text mb-5">
                            <h4>Unfortunately, we do not currently have any properties that match your search criteria.</h4>
                            <a href={NEW_HOMES_SEARCH_PAGE_URL.alias} className="reset-link">Reset the search</a>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

const CustomNoStats = connectStats(NoStats)
// No Results

//map section
class GeoSearch extends React.Component {
    isUserInteraction = true;
    markers = [];
  
    componentDidMount() {
      const { refine } = this.props;
      if (typeof window !== 'undefined') {
        this.instance = new L.map(this.el);
    
        new L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(this.instance);
    
        this.instance.on('moveend', () => {
            if (this.isUserInteraction) {
            const ne = this.instance.getBounds().getNorthEast();
            const sw = this.instance.getBounds().getSouthWest();
    
            refine({
                northEast: { lat: ne.lat, lng: ne.lng },
                southWest: { lat: sw.lat, lng: sw.lng },
            });
            }
        });
      }
    }

    componentDidUpdate() {
      const { hits, currentRefinement, position } = this.props;
      this.markers.forEach(marker => marker.remove());
      if (typeof window !== 'undefined') {
        //const markersCluster = new L.MarkerClusterGroup();
        var markersCluster = new L.markerClusterGroup({
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: true,
            zoomToBoundsOnClick: true
        });
        var myIcon = new L.icon({
            iconUrl: markerImageSrc,
            shadowUrl: '',
        
            iconSize:     [22, 23], // size of the icon
            shadowSize:   [22, 23], // size of the shadow
            iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62],  // the same for the shadow
            popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
        });

        this.markers = hits.map((hit) => 
            markersCluster.addLayer(new L.marker([hit._geoloc.lat, hit._geoloc.lng], {icon: myIcon}).on('click', function() { var target = $("#" + hit.objectID); if (target.length) {  $('html,body').animate({ scrollTop: target.offset().top - 100 }, 10);} }))
        );
        (this.instance).addLayer(markersCluster);
        //markersCluster.addTo(this.instance);
        this.isUserInteraction = false;
        if (!currentRefinement && this.markers.length) {
            this.instance.fitBounds(new L.featureGroup(this.markers).getBounds(), {
            animate: false,
            });
        } else if (!currentRefinement) {
            this.instance.setView(
            position || {
                lat: 48.864716,
                lng: 2.349014,
            },
            12,
            {
                animate: false,
            }
            );
        }
      }
      this.isUserInteraction = true;
    }

    render() {
      const { currentRefinement, refine } = this.props;
  
      return (
        <div id="location-map">
          <div ref={c => (this.el = c)} />
        </div>
      );
    }
  }

const CustomGeoSearch = connectGeoSearch(GeoSearch);

  // map section ends here

const SearchTemplate = (props) => {
    const [sold, setIncludeSold] = React.useState(false);
    const [scroll, setScroll] = useState(false);
    const [underOffer, setIncludeUnderOffer] = React.useState(false);
    const [scrollToTopBtn, setScrollToTop] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 1);
            if (window.pageYOffset > 500) {
                setScrollToTop(true);
            } else {
                setScrollToTop(false);
            }
        });

        // iphone zoom issue when type a text in input field
        $('head').append('<meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0">');
        $('body').addClass("search-template");
        // iphone zoom issue when type a textin input field
    }, []);

    const includeSold = (value) => {
        setIncludeSold(value)
    }

    const includeUnderOffer = (value) => {
        setIncludeUnderOffer(value)
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    };
    return (
        <div className={`${scroll ? 'wrapper search-result-page scroll new-home-search-result' : 'wrapper search-result-page new-home-search-result'}`}>
            <Header type="results" />
            {/* <LoadExternalScript
                src={locRatingScriptsJs}
                // async="true"
                defer="true"
                loadScript={renderMap}
            // appendScriptTo="head"
            /> */}
            <InstantSearch
                indexName={index_name}
                searchClient={client}
                onSearchStateChange={(state) => {
                    props.changeStateAndUrl(state)
                }}
                hits
            >
                <Configure {...props.algoliaConfig} />
                <FilterBlock
                    {...props}
                    // setMapview={setMapUrl}
                    includeSold={includeSold}
                    includeUnderOffer={includeUnderOffer}
                    mapView={props.mapView}
                    searchType={'sales'}
                    query={props.query}
                    index_name={index_name}
                    search_area={props.search_area}
                    h1={props.h1}
                    off_plan={false}
                    sales={true}
                    changeRadius={props.changeRadius}
                />
                <div className="d-none">
                    {/* <RefinementList
                        attribute="brand_id"
                        defaultRefinement={[process.env.GATSBY_STRAPI_BRAND_ID]}
                    /> */}
                    {/* <RefinementList
                        attribute="search_type"
                        defaultRefinement={[props.searchType]}
                    /> */}
                    {/* <RefinementList
                            attribute="status"
                            defaultRefinement={getRefinement()}
                        /> */}
                    {/* <RefinementList
                        attribute="publish"
                        defaultRefinement={["true"]}
                    /> */}
                </div>
                <div className={`search-result-block ${(isTablet || isMobile) ? 'mobile-view-results-page' : ''}`}>
                    <div className="search-list-block">
                        <CustomInfiniteHits {...props} off_plan={false} index_name={index_name} sortByIndex={props.location.pathname.includes("most-recent") ? index_name + "_most_recent" : index_name} />
                        <CustomNoStats />
                    </div>
                    <div className={`${props.mapView ? 'map-box grid-view' : 'map-box'} ${(isTablet || isMobile) ? 'mobile-loc-map' : ''}`} id="res-div-two">
                        <div id="map-holder" className={classNames('map')}>
                        {props.mapView && (isTablet || isMobile) ? <MapResultsDesktop {...props} /> :  <CustomGeoSearch /> }
                        </div>
                    </div>
                </div>


                {
                    props.mapView &&
                    <>
                        {
                            (isTablet || isMobile) &&
                            <div className="map-configure">
                                <Configure
                                    hitsPerPage={2000}
                                />
                            </div>
                        }
                        {/* <MapResultsDesktop /> */}
                    </>
                }
            </InstantSearch>
            {scrollToTopBtn && (
                <button onClick={scrollToTop} className="back-to-top">
                    <i className="icon-up"></i>
                </button>
            )}
            <Footer search={"Popular_Search_Sales"} />
        </div>
    )
}

export default SearchTemplate;
