import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap";
import { connectStats, Pagination, connectPagination } from 'react-instantsearch-dom';
import $ from "jquery";
import ScrollAnimation from "react-animate-on-scroll";
import Select from "react-dropdown-select";
import LoadableAbout from "../FilterBlock/FilterBlock";
import { PropertyCardList } from "./SearchItems";
import { PropertyCardListTablet } from "./SearchItemsTablet";
import { PropertyCardListTabletPro } from "./SearchItemsTabletPro";
import { PropertyCardListDesktop } from "./SearchItemsDesktop";
import PropertyCard from "../PropertyCard/NewHomesPropertyCard"
import Paginations from "../Paginations/Paginations"
import NoImage from "../../../images/no-image.jpg"
import { useWindowSize } from '../../../hooks/winodw-size'
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from "html-react-parser";
import AlertEnquiry from "../SearchAlert";
import Enquiry from "../../Branches/Enquiry";
import { connectSortBy } from "react-instantsearch-core";

import "./SearchListNewHomes.scss";
import {
  isTablet,
  isIPad13,
  isIOS13,
  isMobile,
  isMobileOnly,
  isDesktop,
} from "react-device-detect";

const sortBybox = ({ currentRefinement, defaultRefinement, refine, value, items }) => {
  let sortBy = "";
  if (currentRefinement != null || defaultRefinement != undefined) {
    if (currentRefinement == process.env.GATSBY_ALGOLIA_NEW_DEVELOPMENT_INDEX_NAME) {
      sortBy = defaultRefinement;
    } else {
      sortBy = currentRefinement;
    }
  }
  return (
    <div className="filter-option result-search-recent-filter">
      <i className="icon-sort"></i>
      <Select
        options={items}
        placeholder="Sort"
        classNamePrefix={"select-opt filter-box"}
        className="filter-sortby"
        value={sortBy ? items.find(obj => obj.value == sortBy) || items.find(obj => obj.value == sortBy) : items.find(obj => obj.value == value)}
        searchable={false}
        onChange={event => {
          refine(event[0].value)
        }}
      />
    </div>
  )
}


const CustomSortBy = connectSortBy(sortBybox);
const CustomPagination = ({ currentRefinement, nbPages }) => {
  return (
    <>
      {nbPages > 0 &&
        <div>
          {`Page ${currentRefinement} of ${nbPages}`}
        </div>
      }
    </>
  )
}

const CustomPaginationRender = connectPagination(CustomPagination);

const SearchList = (props) => {
  const [windowWidth] = useWindowSize();
  const [colour, setColour] = useState(false);
  const [mview, setView] = useState('');
  const [mapview, setMapView] = useState('');
  const [second, setSecond] = useState(false);
  const [info, setInfo] = useState(false);

  const handleSecond = () => {
    setSecond(!second);
  }

  const changeColour = () => {
    setColour(!colour);
  }

  const listViewMobile = (e) => {
    $(".list-view").removeClass("active")
    $(".view-pin").addClass("active")
  }

  const mapViewMobile = (e) => {
    $(".list-view").addClass("active")
    $(".view-pin").removeClass("active")
  }

  $(document).on('click', '.ais-Pagination-link', function () {
    if (window) {
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
    }
  });
  // console.log("xxx1",props.mapView)

  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  var blockCount = 0;
  return (
    <React.Fragment>
      <Container id="res-div-one">
        <Row className="property-view">
          <Col md="7">
            <div className={`${props.mapView ? 'grid' : ''}`}>
              <span className="search-number">{props.h1} ({props?.nbHits})
                {/* <i className="icon-info" onClick={() => { setInfo(!info) }}></i> */}
              </span>
              {/* {info &&
                <p> {props.desc} </p>
              } */}
            </div>
          </Col>
          <Col md="5">
            <div>
              <div className="search-right">

                {/* <button type="button" className="alert-btn">
                    <i className="icon-mail"></i>
                    {windowWidth <= 991 && <AlertEnquiry name="" class="mail"><span>Create alert</span></AlertEnquiry>}
                    {windowWidth > 991 && <AlertEnquiry name="" class="mail"><span>Create alert for this search</span></AlertEnquiry>}

                  </button> */}

                {/* <button type="button" className="list-view" onClick={listViewMobile}>
                    <i className="icon-view"></i>
                    <span>List View m</span>
                  </button>
                  <button type="button" className="view-pin active" onClick={mapViewMobile}>
                    <i className="icon-pin"></i>
                    <span>Map View m</span>
                  </button> */}

                {/* {props.mapView ?
                  <button type="button" className="list-view" onClick={() => { props.changeToListUrl(); sessionStorage.setItem('MainView', 'List') }}>
                    <i className="icon-view"></i>
                    <span>List View</span>
                  </button> :
                  <button type="button" className="view-pin" onClick={() => { props.changeToMapUrl(); sessionStorage.setItem('MainView', 'Grid') }}>
                    <i className="icon-pin"></i>
                    <span>Map View</span>
                  </button>
                } */}

                {isTablet || isMobile ?
                  <React.Fragment>
                    {props.mapView ?
                      <button type="button" className="grid-view" onClick={() => { props.changeToListUrl(); sessionStorage.setItem('MainView', 'List') }}>
                        <i className="icon-view"></i>
                        <span>List View</span>
                      </button> :
                      <button type="button" className="grid-view" onClick={() => { props.changeToMapUrl(); sessionStorage.setItem('MainView', 'Grid') }}>
                        <i className="icon-grid"></i>
                        <span>{isTablet || isMobile ? "Map View" : "Grid View"}</span>
                      </button>
                    }
                  </React.Fragment> : ''
                }
                {/* <button type="button" className="sort-list" >
                  <i className="icon-sort"></i>
                  <span>Most Recent</span>
                </button> */}
                {/* <div className="recent-filter-block">
                  <CustomSortBy
                    defaultRefinement={props.sortByIndex}
                    items={[
                      { value: props.index_name, label: "Sort" }, 
                      { value: props.index_name + "_price_desc", label: "Highest Price First" },
                      { value: props.index_name + "_price_asc", label: "Lowest Price First" }
                    ]}
                  />

                </div> */}

              </div>
            </div>
          </Col>
          <p className="col-md-12 mb-0 mt-2"> {props.desc} </p>
        </Row>

        <div className={`${props.mapView ? 'property-card-list lg-view' : 'no-pad'}`}>
          <Row>
            {props?.hits.map((item, i) => {
              var url = '/new-home-for-sale/' + item.slug + '-' + item.objectID
              var image_url = ''
              var sub_image_url_one = ''
              var sub_image_url_two = ''
              var sub_image_url_three = ''
              console.log('images', item)

              if (item.images1) {
                image_url = item.images1.images_src[0];
                // var image_temp = image_url.substr(image_url.indexOf('i.dev'))
              }
              if (item.images2) {
                sub_image_url_one = item.images2.images_src[0];
                sub_image_url_two = item.images2.images_src[1];
                sub_image_url_three = item.images2.images_src[2];
              }

              // console.log("ggfx",item.imagetransforms)
              let processedImages = JSON.stringify({});
              if (item.imagetransforms?.images_Transforms) {
                processedImages = item.imagetransforms.images_Transforms
                // console.log("ccc1",JSON.parse(processedImages),item.objectID)
              }

              // console.log("ccc",item.imagetransforms,{[image_temp] : item.images[0]})

              return (
                <React.Fragment>
                  {props.mapView ?
                    <PropertyCard
                      image_url={image_url}
                      // BoxColor={item.BoxColor}
                      BedCount={item.bedroom}
                      BathCount={item.bathroom}
                      areaSup={item.reception}
                      PropertyPricePrefix={item.price_prefix}
                      min_price={item.min_price}
                      max_price={item.max_price}
                      PropertyPrice={item.price_range}
                      PropertyLine={item.development_title}
                      PropertyHeading={item.development_title}
                      PropertyText={item.short_description}
                      processedImages={processedImages}
                      objectID={item.objectID}
                      url={url}
                      type="new-homes"
                      showcased_property={item.showcased_property}
                      spotlight_property={item.spotlight_property}
                      virtual_tour={item.virtual_tour}
                      floorplan={item.floorplan}
                      imageCount={item.imageCount}
                    />
                    :
                    <Col lg="12" md="6" className="d-md-block" id={item.objectID}>
                      {item.showcased_property ?
                        <div className="top-bar showcased">
                          <span className="top-bar-text">Showcased Property</span>
                        </div> : item.spotlight_property ?
                          <div className="top-bar spotlighted">
                            <span className="top-bar-text">spotlighted Property</span>
                          </div> : ''}

                      <div className={`property-card property-card-big ${item.showcased_property ? 'property-card-top' : item.spotlight_property ? 'spotlighted property-card-top' : 'img-zoom'}`}>
                        <div className="properties-wrapper">
                          <Link to={`${url}`} className="img-link">
                            <picture className={item.showcased_property ? 'img-zoom' : item.spotlight_property ? 'img-zoom' : ''}>
                              {image_url ?
                                <LazyLoadImage
                                  wrapperClassName="img-zoom"
                                  wrapperProps={{ style: { display: "block" } }}
                                  alt={'property Image'}
                                  effect="blur"
                                  src={image_url} /> :
                                <img src={NoImage} alt="img" />
                              }
                            </picture>

                            <div className="gallery-img d-lg-flex d-none">
                              {sub_image_url_one ?
                                <LazyLoadImage
                                  wrapperProps={{ style: { display: "contents" } }}
                                  alt={'property Image'}
                                  effect="blur"
                                  src={sub_image_url_one} />
                                :
                                <img src={NoImage} alt="img" />
                              }
                              {sub_image_url_two ?
                                <LazyLoadImage
                                  wrapperProps={{ style: { display: "contents" } }}
                                  alt={'property Image'}
                                  effect="blur"
                                  src={sub_image_url_two} /> :
                                <img src={NoImage} alt="img" />
                              }
                              {sub_image_url_three ?
                                <LazyLoadImage wrapperProps={{ style: { display: "contents" } }}
                                  alt={'property Image'}
                                  effect="blur"
                                  src={sub_image_url_three} /> :
                                <img src={NoImage} alt="img" />
                              }
                            </div>
                            <div className="btn-group-wrap">
                              <button className="btn-camera"><i className="icon-camera-gallery"></i> <span>1/{item.imageCount}</span></button>
                              {item.virtual_tour &&
                                <button className="btn-play-circle"><i className="icon-play-circle"></i></button>}
                              {item.floorplan?.url &&
                                <button className="btn-flor"><i className="icon-flor"></i> </button>
                              }
                            </div>
                          </Link>
                        </div>
                        <div className="about-properties">

                          <div className="properties-info">

                            <h2>
                              <Link to={`${url}`}> {item.development_title}</Link>
                            </h2>
                            {
                              item.min_price && item.max_price &&
                              <React.Fragment>
                                {item.min_price == item.max_price ?
                                  <span className="property-price d-flex align-items-center">
                                    Price £{item.min_price ? item.min_price.toLocaleString() : 0}
                                  </span> :
                                  <span className="property-price d-flex align-items-center">
                                    {item.price_prefix} £{item.min_price ? item.min_price.toLocaleString() : 0} - £{item.max_price ? item.max_price.toLocaleString() : 0}
                                  </span>
                                }
                              </React.Fragment>
                            }
                            <div className="properties-icons d-lg-flex d-none">
                            </div>
                            {item.short_description ?
                              <p className="d-lg-block d-none">{parse(item.short_description.substr(0, 150))}...</p> :
                              <p className="d-lg-block d-none">{parse(item.long_description.substr(0, 150))}...</p>}
                            <div className="bottom-block d-lg-block d-none">
                              {/* <Link to="#" className="number-text">
                        <i className="icon-phone"></i>
                        <span className="call-to">01227 479317</span>
                      </Link> */}

                              <a href="javascript:void(0);" className="mail-text">
                                <i className="icon-envelope"></i>
                                <Enquiry name="" class="mail"><span>Enquire</span></Enquiry>
                              </a>
                              {/* <Link to="#" className="save-list" onClick={handleSecond}>
                        <i className={second ? "icon-heart-fill" : "icon-heart"}>
                        </i>
                        <span>Save</span>
                      </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>}
                </React.Fragment>
              )
            })}

            {props.mapView && (isTablet || isMobile) ? '' :

              <div className="justify-content-center">
                {props?.nbHits > 20 &&
                  <>

                    <React.Fragment>
                      <Col lg={12} className="d-flex justify-content-center py-3">
                        <Pagination
                          showFirst={true}
                          showPrevious={true}
                          showNext={true}
                          showLast={true}
                          // defaultRefinement={props.page}
                          padding={2}
                        />
                      </Col>
                      <Col lg={12} className="d-flex justify-content-center py-3">
                        <CustomPaginationRender />
                      </Col>
                    </React.Fragment>
                  </>
                }
              </div>
            }

          </Row>
        </div>

      </Container>
    </React.Fragment>
  );
};

export default connectStats(SearchList);