import React, { useState } from "react";
import { Link } from "gatsby";
import "./PropertyCard.scss";
// import PlayVideo from "../../Components/Play/PlayVideo";

import NoImage from "../../../images/no-image.jpg"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

const PropertyCard = (props) => {

  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  }


  const ImageRender = ({ image_url, node, processedImages }) => {
    return (
      <img src={image_url} alt="" />
      // <ImageTransform imagesources={image_url} renderer="srcSet" imagename="property.images.searchResults1" attr={{ alt: '', class: '' }} imagetransformresult={processedImages} id={node.crm_id} format="source" />
    )
  }


  return (
    <React.Fragment>
      <div className={`property-card img-zoom ${props.showcased_property ? 'box-blue' : props.spotlight_property ? 'box-yellow' : ''}`} id={props.objectID}>
        <div className="properties-wrapper">
          <Link to={`${props.url}`} className="img-link">
            <picture>
              {props.image_url ?
                <ImageRender processedImages={props.processedImages} image_url={props.image_url} node={props} />
                :
                <img src={NoImage} alt="img" />
              }
              {/* <img src={props.ImgLarge} alt="img" /> */}
            </picture>
            {props.showcased_property ?
              <span className="card-line">Showcased Property</span> :
              props.spotlight_property ?
                <span className="card-line">spotlighted Property</span> : ''}

            <div className="btn-group-wrap">
              <button className="btn-camera"><i className="icon-camera-gallery"></i> <span>1/{props.imageCount}</span></button>
              {props.virtual_tour &&
                <button className="btn-play-circle"><i className="icon-play-circle"></i></button>
              }
              {props.floorplan?.url &&
                <button className="btn-flor"><i className="icon-flor"></i> </button>
              }
            </div>
          </Link>
        </div>
        <div className="about-properties grid-prop-info">

          <div className="properties-info">

            <h2 onClick={handleClick}>

              <Link to={`${props.url}`} className="img-link">
                {props.PropertyHeading}
              </Link>
              {/* {props.login == "true" ?
                <i id={props.crm_id} className={props.wish == "true" ? "icon-heart-fill" : "icon-heart"} onClick={() => { props.handleSecond(props.crm_id); }}></i> :
                <a href="https://myaccount.wardsofkent.co.uk/Account/Login" target="_blank">
                  <i id={props.crm_id} className="icon-heart"></i>
                </a>
              } */}
            </h2>
            {
              props.min_price && props.max_price &&
              <React.Fragment>
                {props.min_price == props.max_price ?
                  <span className="property-price d-flex align-items-center">
                    Price £{props.min_price ? props.min_price.toLocaleString() : '0'}
                  </span> :
                  <span className="property-price d-flex align-items-center">
                    {props.PropertyPricePrefix} £{props.min_price ? props.min_price.toLocaleString() : '0'} - £{props.max_price ? props.max_price.toLocaleString() : '0'}
                  </span>
                }
              </React.Fragment>
            }
            <p>{props.PropertyText}</p>
          </div>
        </div>

      </div>
    </React.Fragment>
  );
};

export default PropertyCard;
